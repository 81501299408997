<template>
    <div class="page">
        <div class="title">近7天上链情况</div>
        <div class="chartContainer">
            <div id="chart" ref="chart"></div>
        </div>
    </div>
</template>

<script>
    import * as echarts from 'echarts';
    import axiosCB from '@/axios/indexCB'
    import * as moment from 'moment'
    import { findCloseNum } from '@/comm/ut'
    export default {
        data() {
            return {
                list: [
                    { month: moment().subtract(8, 'h').format("MM"), days: moment().subtract(8, 'h').format("DD"), startAt: moment().subtract(8, 'h').subtract(1, 'days').valueOf(), timeStr: moment().subtract(8, 'h').subtract(1, 'days').format("YYYY-MM-DD HH:mm:ss"), endAt: moment().subtract(8, 'h').valueOf() },
                    { month: moment().subtract(8, 'h').subtract(1, 'days').format("MM"), days: moment().subtract(8, 'h').subtract(1, 'days').format("DD"), startAt: moment().subtract(8, 'h').subtract(2, 'days').valueOf(), timeStr: moment().subtract(8, 'h').subtract(2, 'days').format("YYYY-MM-DD HH:mm:ss"), endAt: moment().subtract(8, 'h').subtract(1, 'days').valueOf() },
                    { month: moment().subtract(8, 'h').subtract(2, 'days').format("MM"), days: moment().subtract(8, 'h').subtract(2, 'days').format("DD"), startAt: moment().subtract(8, 'h').subtract(3, 'days').valueOf(), timeStr: moment().subtract(8, 'h').subtract(3, 'days').format("YYYY-MM-DD HH:mm:ss"), endAt: moment().subtract(8, 'h').subtract(2, 'days').valueOf() },
                    { month: moment().subtract(8, 'h').subtract(3, 'days').format("MM"), days: moment().subtract(8, 'h').subtract(3, 'days').format("DD"), startAt: moment().subtract(8, 'h').subtract(4, 'days').valueOf(), timeStr: moment().subtract(8, 'h').subtract(4, 'days').format("YYYY-MM-DD HH:mm:ss"), endAt: moment().subtract(8, 'h').subtract(3, 'days').valueOf() },
                    { month: moment().subtract(8, 'h').subtract(4, 'days').format("MM"), days: moment().subtract(8, 'h').subtract(4, 'days').format("DD"), startAt: moment().subtract(8, 'h').subtract(5, 'days').valueOf(), timeStr: moment().subtract(8, 'h').subtract(5, 'days').format("YYYY-MM-DD HH:mm:ss"), endAt: moment().subtract(8, 'h').subtract(4, 'days').valueOf() },
                    { month: moment().subtract(8, 'h').subtract(5, 'days').format("MM"), days: moment().subtract(8, 'h').subtract(5, 'days').format("DD"), startAt: moment().subtract(8, 'h').subtract(6, 'days').valueOf(), timeStr: moment().subtract(8, 'h').subtract(6, 'days').format("YYYY-MM-DD HH:mm:ss"), endAt: moment().subtract(8, 'h').subtract(5, 'days').valueOf() },
                    { month: moment().subtract(8, 'h').subtract(6, 'days').format("MM"), days: moment().subtract(8, 'h').subtract(6, 'days').format("DD"), startAt: moment().subtract(8, 'h').subtract(7, 'days').valueOf(), timeStr: moment().subtract(8, 'h').subtract(7, 'days').format("YYYY-MM-DD HH:mm:ss"), endAt: moment().subtract(8, 'h').subtract(6, 'days').valueOf() }
                ]
            }
        },
        async created() {
            this.getData()
        },
        methods: {

            async getData() { //请求数据 设置数据
                let reqList = []
                for (let i = 0; i < this.list.length; i++) {
                    const item = this.list[i];
                    reqList.push(axiosCB.request({
                        url: `/network/${this.$store.state.networkName}/transactions/count`,
                        method: "GET",
                        params: {
                            startAt: item.startAt,
                            endAt: item.endAt
                        }
                    }))
                }
                let responseList = await Promise.all(reqList)
                let visualMapMax = 0
                let xAxisData = []
                let data = []

                for (let i = 0; i < this.list.length; i++) {
                    const item = this.list[i];
                    item.value = responseList[i].data
                    if (item.value > visualMapMax) visualMapMax = item.value
                    xAxisData.push(`${item.month}/${item.days}`)
                    data.push(item.value)

                }
                this.chart = echarts.init(this.$refs.chart)

                let valueArry = this.list.map(item => parseFloat(item.value))
                const arrayAverage = arr => arr.reduce((acc, val) => acc + val, 0) / arr.length; //求平均值函数
                let actNumber = findCloseNum(valueArry, arrayAverage(valueArry)) //最接近平均值的值
                let seriesData = []
                for (let i = 0; i < this.list.length; i++) {
                    const item = this.list[i];
                    seriesData.push({
                        value: item.value,
                        label: {
                            show: item.value == actNumber && actNumber!=0 ? true : false
                        }
                    })

                }
                this.chart.setOption({
                    grid: {
                        width: '92%',
                        left: '5%'
                    },
                    tooltip: {
                        show: true,
                    },
                    xAxis: {
                        boundaryGap: false, // 不留白，从原点开始
                        data: xAxisData,
                        show: true,
                        axisLine: {
                            lineStyle: {
                                color: "#CCCCCC"
                            }
                        },
                        axisLabel: {
                            color: "#999999"
                        }
                    },
                    yAxis: {
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#CCCCCC"
                            }
                        },
                        axisLabel: {
                            color: "#999999"
                        },
                        show: true
                    },
                    series: [
                        {
                            type: 'bar',
                            name: '上链记录数量趋势',
                            tooltip: {
                                show: false
                            },
                            animation: false,
                            barWidth: 1.4,
                            hoverAnimation: false,
                            data,
                            itemStyle: {
                                normal: {
                                    color: 'rgba(255, 255, 255, 0.08)',
                                    label: {
                                        show: false
                                    }
                                }
                            }
                         },
                        {
                            type: 'line',
                            name: '上链记录数量趋势',
                            smooth: true,
                            symbolSize: 10,
                            animation: false,
                            lineWidth: 1.2,
                            hoverAnimation: false,
                            data: seriesData,
                            symbol: 'circle',
                            itemStyle: {
                                normal: {
                                    color: 'rgba(29, 201, 183, 1)',
                                    shadowBlur: 0,
                                    label: {
                                        distance: 0,
                                        show: false,
                                        position: 'top',
                                        textStyle: {
                                            color: '#FFFFFF',
                                        },
                                        formatter: (params) => {
                                            return `{a|${params.value}}\n{b|}`
                                        },
                                        fontSize: 16,
                                        rich: {
                                            a: {
                                                color: "#FFFFFF",
                                                verticalAlign: "middle",
                                                padding: [6, 12, 3, 12],
                                                borderRadius: 15,
                                                backgroundColor: "rgba(29, 201, 183, 1)",
                                                textShadowBlur: 0,
                                                shadowBlur: 0
                                                // backgroundColor:{
                                                //     image:"https://fastly.jsdelivr.net/gh/apache/echarts-website@asf-site/zh/images/logo.png?_v_=20200710_1"
                                                // }
                                            },
                                            b: {
                                                lineHeight: 9,
                                                align: "center",
                                                textShadowBlur: 0,
                                                shadowBlur: 0,
                                                textShadowOffsetY: -10,
                                                backgroundColor: {
                                                    image: require("@/assets/image/sjx.png")
                                                }

                                            }
                                        }
                                    }
                                },

                            },
                            areaStyle: {
                                normal: {
                                    color: new echarts.graphic.LinearGradient(
                                        0, 0, 0, 1, //渐变色在下面修改，这里是透明度
                                         [{
                                                offset: 1,
                                                color: 'rgba(29, 201, 183, 0)'
                                            },
                                            {
                                                offset: 0,
                                                color: 'rgba(29, 201, 183, 1)'
                                            },
                                     ]
                                    ),
                                    opacity: 0.4
                                }
                            }
                        }
                    ]
                })

            },
        }
    }
</script>

<style scoped lang="less">
    .page {
        height: calc(100vh - 128px);
        display: flex;
        flex-direction: column;
    }

    .title {
        font-size: 20px;
        font-weight: 600;
        color: #333333;
        padding-top: 32px;
    }

    .chartContainer {
        width: 100%;
        height: 600px;
        background-color: #FFFFFF;
        margin-top: 16px;
    }

    #chart {
        width: 100%;
        height: 100%;
    }
</style>